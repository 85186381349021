import React from "react"
import { graphql } from "gatsby"

import { NavMinimal, Seo } from "../components/Shared"
import { Quote, ProgressBar } from "../components/BoilerQuote"

import questionsJSON from "../components/BoilerQuote/questions.json"

const BoilerQuotePage = ({ data }) => {
  React.useEffect(() => {
    // Making background light grey on mount
    document.body.classList.add("background-grey-lightest")

    return () => {
      // Resetting background colour on unmount so colour doesn't persist on future pages
      document.body.classList.remove("background-grey-lightest")
    }
  }, [])

  // Starting at question 1 / already progress shown for UX 'Goal Gradient Effect'
  const [currentQuestion, setCurrentQuestion] = React.useState(1)
  const [progress, setProgress] = React.useState(0)
  const [answers, setAnswers] = React.useState({
    boilerSituation: "",
    fuelType: "",
    currentBoilerType: "",
    combiConversion: false,
    boilerMove: false,
    propertyType: "",
    bedrooms: 0,
    bathtubs: 0,
    showers: 0,
    radiators: 0,
    boilerFlueExit: "",
    postcode: "",
  })

  React.useEffect(() => {
    // + 1 for postcode question
    setProgress(currentQuestion / (questionsJSON.questions.length + 1))
  }, [currentQuestion])

  return (
    <div className="background-grey-lightest">
      <Seo
        url={data.site && data.site.siteMetadata.url + "/quote"}
        title={data.seo && data.seo.title}
        description={data.seo.metaDescription.metaDescription}
        image={
          data.seo &&
          data.seo.image &&
          data.seo.image.file &&
          data.seo.image.file.url
        }
      />
      <NavMinimal />
      <ProgressBar progress={progress} />
      <Quote
        allQuestions={questionsJSON.questions}
        currentQuestion={currentQuestion}
        setAnswers={setAnswers}
        setCurrentQuestion={setCurrentQuestion}
        answers={answers}
      />
    </div>
  )
}

export default BoilerQuotePage

export const pageQuery = graphql`
  query getQuotePageSeo {
    site {
      siteMetadata {
        url
      }
    }
    seo: contentfulSeo(contentful_id: { eq: "2i1dMFswT1bfDGWVcfcP0M" }) {
      image {
        fluid {
          src
        }
        title
        description
      }
      title
      metaDescription {
        metaDescription
      }
    }
  }
`
